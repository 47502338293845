export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
}

const data: IMenuItem[] = [
  {
    id: 'fsg',
    icon: 'iconsminds-dashboard',
    label: 'Dashboard',
    to: '/app/fsg/start',
  },
  {
    id: 'second-menu',
    icon: 'iconsminds-files',
    label: 'Agreements',
    to: '/app/fsg/Agreements',
  },

];
export default data;
